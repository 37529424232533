import React, { Component } from 'react';
import classes from './App.css';
import Nav from "../Components/Nav/";
import Main from "../Components/Main/";
import Footer from "../Components/Footer/";

class App extends Component {
  render() {
    return (
      <div className={classes.Container}>
          <Nav/>
          <Main/>
          <Footer/>
      </div>
    );
  }
}

export default App;
