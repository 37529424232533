import React, {Component} from "react";
import classes from "./styles.css";
import images from "../../assets/images/";
import icons from "../../assets/icons/";

class Main extends Component {
  render() {
    const toggleViewOn = (e) => {
      e.target.childNodes[0].classList.remove(classes.hidden);
    }
    const toggleViewOff = (e) => {
      e.target.childNodes[0].classList.add(classes.hidden);
    }
    return (
      <div className={classes.Container}>
        <div className={classes.Container__splashPage}>
          <div className={`${classes.Container__InnerContent} ${classes.flexColumn}`}>
            <div className={`${classes.Container__Image} ${classes.margin1}`}></div>
            <h1 className={`${classes.largeFont} ${classes.margin1}`}>Eric Gomez</h1>
            <hr className={classes.margin3}/>
            <h3>Full-Stack Web Developer / Software Engineer</h3>
          </div>
        </div>
        <div id="about" className={classes.Container__aboutPage}>
          <div className={`${classes.Container__InnerContent} ${classes.flexRow}`}>
            <div className={classes.aboutPage__LeftContent}>
              <h2 className={classes.largeFont}>about me</h2>
              <div className={classes.aboutPage__ContentContainer}>
                <div className={classes.aboutPage__Summary}>
                  I'm a former open surface miner turned full-stack web developer based in Mesquite, Nevada.
                </div>
                <div className={classes.aboutPage__Bio}>
                  I enjoy turning complex problems into simple and intuitive designs.
                  When Im not pushing pixels, you'll find me gaming, skateboarding or working out at the gym.
                </div>
              </div>
              <div className={classes.aboutPage__SkillsContainer}>
                <h3>Skills</h3>
                <div className={classes.aboutPage__Icons}>
                  <img src={icons.js} alt="" className={classes.aboutPage__Skill}/>
                  <img src={icons.react} alt="" className={classes.aboutPage__Skill}/>
                  <img src={icons.reduxsm} alt="" className={classes.aboutPage__Skill}/>
                  <img src={icons.mongosm} alt="" className={classes.aboutPage__Skill}/>
                  <img src={icons.rustsm} alt="" className={classes.aboutPage__Skill}/>
                  <img src={icons.pythonsm} alt="" className={classes.aboutPage__Skill}/>
                  <img src={icons.redissm} alt="" className={classes.aboutPage__Skill}/>
                  <img src={icons.clangsm} alt="" className={classes.aboutPage__Skill}/>
                </div>
              </div>
            </div>
            <div className={classes.aboutPage__RightContent}>
              <img src={images.comp} alt="" className={classes.roundBorder}/>
            </div>
          </div>
        </div>
        <div id="portfolio" className={classes.Container__Portfolio}>
          <div className={classes.Container__InnerContent}>
            <h2 className={classes.largeFont}>portfolio</h2>
            <div className={classes.Portfolio__RowContainer}>
              <div className={classes.Portfolio__Row}>
                <div className={`${classes.mdImage} ${classes.PortfolioItem} ${classes.height40}`} onClick={() => window.open("https://codepen.io/Eric_Gomez11011/full/ZjwKdX/")} onMouseOut={(e) => toggleViewOff(e)} onMouseOver={(e) => toggleViewOn(e)}>
                  <div className={`${classes.PortfolioItem__Description} ${classes.hidden}`}>
                    <h2 className={classes.PortfolioItem__Header}>MarkDown Viewer</h2>
                    <p className={classes.PortfolioItem__Body}>an app which converts githubs markdown syntax into html</p>
                  </div>
                </div>
                <div className={`${classes.pomo} ${classes.PortfolioItem} ${classes.height30}`} onClick={() => window.open("https://codepen.io/Eric_Gomez11011/full/pVRGJg/")} onMouseOut={(e) => toggleViewOff(e)} onMouseOver={(e) => toggleViewOn(e)}>
                  <div className={`${classes.PortfolioItem__Description} ${classes.hidden}`}>
                    <h2 className={classes.PortfolioItem__Header}>Pomodoro Clock</h2>
                    <p className={classes.PortfolioItem__Body}>a simple pomodoro clock. created with the follow tools: Javascript, HTML, CSS</p>
                  </div>
                </div>
              </div>
              <div className={classes.Portfolio__Row}>
                <div className={`${classes.notes} ${classes.PortfolioItem} ${classes.height50}`} onClick={() => window.open("https://jovial-roentgen-82aaf3.netlify.com/")} onMouseOut={(e) => toggleViewOff(e)} onMouseOver={(e) => toggleViewOn(e)}>
                  <div className={`${classes.PortfolioItem__Description} ${classes.hidden}`}>
                    <h2 className={classes.PortfolioItem__Header}>Notes</h2>
                    <p className={classes.PortfolioItem__Body}>A simple note taking app created with React, Node.js, Express, and Mongodb. all notes are saved, updated, and deleted by making CRUD requests to to the server. offers persistant image support, as well as markdown.</p>
                  </div>
                </div>
                <div className={`${classes.weather} ${classes.PortfolioItem} ${classes.height30}`} onClick={() => window.open("https://codepen.io/Eric_Gomez11011/full/wgaZLV/")} onMouseOut={(e) => toggleViewOff(e)} onMouseOver={(e) => toggleViewOn(e)}>
                  <div className={`${classes.PortfolioItem__Description} ${classes.hidden}`}>
                    <h2 className={classes.PortfolioItem__Header}>Weather</h2>
                    <p className={classes.PortfolioItem__Body}>a simple app which tells you the current temperature and forecast for the day, it works simply by interfacing with a weather API</p>
                  </div>
                </div>
              </div>
              <div className={classes.Portfolio__Row}>
                <div className={`${classes.atlas} ${classes.PortfolioItem} ${classes.height30}`} onClick={() => window.open("https://atlas-minerva.herokuapp.com/")} onMouseOut={(e) => toggleViewOff(e)} onMouseOver={(e) => toggleViewOn(e)}>
                  <div className={`${classes.PortfolioItem__Description} ${classes.hidden}`}>
                    <h2 className={classes.PortfolioItem__Header}>Atlas</h2>
                    <p className={classes.PortfolioItem__Body}>Atlas is a social networking app which allows current job seekers to match up with recruiters in their area by simply looking for job postings in through the app.</p>
                  </div>
                </div>
                <div className={`${classes.twitch} ${classes.PortfolioItem} ${classes.height40}`} onClick={() => window.open("https://codepen.io/Eric_Gomez11011/full/rJmxZj/")} onMouseOut={(e) => toggleViewOff(e)} onMouseOver={(e) => toggleViewOn(e)}>
                  <div className={`${classes.PortfolioItem__Description} ${classes.hidden}`}>
                    <h2 className={classes.PortfolioItem__Header}>Twitch Streamers</h2>
                    <p className={classes.PortfolioItem__Body}>a simple app which interfaces with the twitch api to display the streamers i follow and also displays their current status and which game they are currently playing.</p>
                  </div>
                </div>
              </div>
              <div className={classes.Portfolio__Row}>
                <div className={`${classes.wiki} ${classes.PortfolioItem} ${classes.height35}`} onClick={() => window.open("https://codepen.io/Eric_Gomez11011/full/oBWJEo/")} onMouseOut={(e) => toggleViewOff(e)} onMouseOver={(e) => toggleViewOn(e)}>
                  <div className={`${classes.PortfolioItem__Description} ${classes.hidden}`}>
                    <h2 className={classes.PortfolioItem__Header}>Wikipedia Viewer</h2>
                    <p className={classes.PortfolioItem__Body}>an app that takes the text typed into the search field and interfaces with the wikipedia API to return a list of results related to the search text.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Main;
