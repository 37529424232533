import React, {Component} from "react";
import classes from "./styles.css";

class Footer extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div className={classes.Container}>
        <p>&copy; 2018 Eric Gomez</p>
        <a href="https://icons8.com/">icons8.com</a>
      </div>
    )
  }
}

export default Footer;
