import React, {Component} from 'react';
import classes from "./styles.css";
import email from "../../assets/icons/email.png";
import linkedin from "../../assets/icons/linkedin.png";

class Nav extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const copy = (e) => {
      const str = e.target.dataset.email;
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
    return (
      <nav className={classes.Container}>
        <div className={classes.InnerContainer}>
          <h1>Eric Gomez</h1>
          <div className={classes.Container__LinksContainer}>
            <a href="#portfolio">Portfolio</a>
            <a href="#about">About</a>
            <div onClick={(e) => copy(e)} data-email="eric_gomez_1@hotmail.com" className={`${classes.Container__IconsContainer} ${classes.emailLink}`}>
              <img src={email} alt="" width="25px" height="25px" className={classes.noevent}/>
            </div>
            <a href="https://www.linkedin.com/in/eric-gomez-2a7008113/">
              <div className={classes.Container__IconsContainer}>
                <img src={linkedin} alt="" width="25px" height="25px"/>
              </div>
            </a>
          </div>
        </div>
      </nav>
    )
  }
}
export default Nav;
